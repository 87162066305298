import styled, { css } from "styled-components";
import {
  Rem, BP, Ratio, Colors, Font, 
} from "../../commons/Theme";
import { StyledButton } from "../Button/style";

export const StyledIntro = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto ${Rem(50)};
  height: 100%;
  margin-top: ${(props) => props.marginTop ?? 0};

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    margin: ${(props) => props.marginTop ?? 0} auto ${Rem(70)};
  }
  
  ${(props) =>
    props.black
    && css`
      color: ${Colors.white};
      padding-top: ${Rem(44)};

      @media (${BP.ipad}) {
        padding-top: ${Rem(84)};

        ${StyledIntroTitle} {
          padding-top: ${Rem(84)};
        }
      }
    `}

  ${(props) =>
    props.isCentered
    && css`
      @media (${BP.ipad}) {
        justify-content: center;
      }
    `}

  ${(props) =>
    props.gray
    && css`
      background-color: ${Colors.grayLight};
    `}

  ${(props) =>
    props.black
    && css`
      background-color: ${Colors.black};
    `}
    
  ${(props) =>
    props.hasImage
    && css`
      ${StyledIntroImageWrapper} + ${StyledIntroBlock} {
        padding-left: ${Rem(24)};
        @media (${BP.ipad}) {
          position: absolute;
          left: ${Rem(653)};
          padding-left: ${Rem(80)};
        }
      }
    `}
  
  ${(props) =>
    props.hasCTA
    && css`
      padding-bottom: ${Rem(120)};

      @media (${BP.ipad}) {
        padding-bottom: ${Rem(0)};
      }
    `}
  
  ${(props) =>
    props.hasGallery
    && css`
      @media (${BP.ipad}) {
        justify-content: center;
      }

      ${StyledIntroBlock}:last-child {
        @media (${BP.ipad}) {
          max-width: ${Rem(500)};
          padding-right: ${Rem(24)};
          padding-bottom: ${Rem(110)};
        }
      }
    `}
    
  ${(props) =>
    props.hasGallery
    && props.hasCTA
    && css`
      ${StyledIntroCTA} {
        @media (${BP.ipad}) {
        }
      }

      @media (${BP.ipad}) {
      }
    `}
`;

export const StyledIntroImageWrapper = styled.div`
  width: 100%;
  max-height: ${Rem(450)};
  overflow: hidden;

  @media (${BP.ipad}) {
    min-width: ${Rem(653)};
    max-width: ${Rem(653)};
  }

  ${(props) =>
    props.fullheight
    && css`
      max-height: ${Rem(950)};
      @media (${BP.ipad}) {
        min-width: ${Rem(400)};
        max-width: ${Rem(400)};
      }
    `}
`;

export const StyledIntroGalleryWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  @media (${BP.ipad}) {
    position: relative;
    padding-top: ${Rem(54)};
    padding-left: ${Rem(8)};
    max-width: ${Rem(700)};
  }
`;

export const StyledIntroImage = styled.div`
  ${Ratio(375, 425)};
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.fullheight
    && css`
      ${Ratio(375, 475)};
    `
}

  @media(${BP.ipad}) {
    ${Ratio(653, 425)};
  }
`;

export const StyledIntroBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media (${BP.ipad}) {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:last-child {
    align-items: flex-start;

    @media (${BP.ipad}) {
      position: relative;
      max-width: calc(100% - ${Rem(210)});
    }
  }

  ${(props) =>
    props.hasPadding
    && css`
      padding: ${Rem(24)} ${Rem(20)};

      @media (${BP.ipad}) {
        position: relative;
        width: 100%;
        padding: ${Rem(50)} ${Rem(80)};
      }
    `}
`;

export const StyledIntroTitle = styled.h3`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  text-transform: uppercase;
  padding-top: ${Rem(16)};

  @media (${BP.ipad}) {
    max-width: ${Rem(200)};
  }
  @media (${BP.desktop}) {
    max-width: none;
  }

  ${(props) =>
    props.hideTextOnMobile
    && css`
      display: none;

      @media (${BP.ipad}) {
        display: inline-block;
      }
    `}

  ${(props) =>
    !props.hideTextOnMobile
    && css`
      margin-bottom: ${Rem(32)};

      @media (${BP.ipad}) {
        display: inline-block;
        margin-bottom: 0;
      }
    `}
  
  ${(props) =>
    props.showSubtitleMobile
    && css`
      margin-bottom: ${Rem(12)};
    `}
  
  br {
    display: none;
    @media (${BP.ipad}) {
      display: block;
    }
  }
`;

export const StyledIntroSubtitle = styled.h4`
  font-size: ${Rem(15)};
  line-height: ${Rem(24)};
  display: none;
  
  ${(props) =>
    props.showSubtitleMobile
    && css`
      display: inline-block;
      margin-bottom: ${Rem(32)};
    `}

  @media (${BP.ipad}) {
    display: inline-block;
    font-size: ${Rem(16)};
    max-width: ${Rem(380)};
    margin-bottom: 0;
  }
`;

export const StyledIntroBigText = styled.h2`
  font-size: ${Rem(30)};
  line-height: ${Rem(38)};
  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(58)};
  }

  > br {
    display: none;
    @media (${BP.ipad}) {
      display: initial;
    }
  }
`;

export const StyledIntroSmallText = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-top: ${Rem(32)};
  max-width: ${Rem(690)};
  
  @media (${BP.ipad}) {
    padding-top: ${Rem(40)};
  }
`;

export const StyledIntroCTA = styled.div`
  position: absolute;
  bottom: 0;

  ${StyledButton} {
    background-color: transparent;
  }

  @media (${BP.ipad}) {
    position: relative;
    bottom: auto;
  }
`;

export const StyledQrCode= styled.img`
  max-height: 100px;
`;
