import React, { useEffect, useRef } from "react";
import {
  StyledPartnersCarousel,
  StyledPartnersCarouselItem,
  StyledPartnersCarouselItemLogo,
  StyledPartnersCarouselSlide,
  StyledPartnersCarouselSwiperWrapper,
  StyledPartnersCarouselControlPrev,
  StyledPartnersCarouselControlNext,
} from "./style";
import Intro from "../Intro/Intro";
import { StyledGridRow } from "../../commons/Grid";
import Swiper, { Navigation } from "swiper";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";

Swiper.use([Navigation]);

export default ({ intro, partners }) => {
  const sliderEl = useRef(null);
  const prevArrowEl = useRef(null);
  const nextArrowEl = useRef(null);

  useEffect(() => {
    if (sliderEl && window.innerWidth >= 1024) {
      new Swiper(sliderEl.current, {
        allowTouchMove: "ontouchstart" in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        loop: true,
        slidesPerView: 4,
        centeredSlides: true,
        navigation: {
          prevEl: prevArrowEl.current,
          nextEl: nextArrowEl.current,
        },
        breakpoints: {
          1024: {
            slidesPerView: "auto",
          },
        },
      });
    }
  }, [sliderEl]);

  return (
    <StyledPartnersCarousel useSymmetricPadding={intro == null}>
      {intro 
        && <Intro {...intro} />
      }
      <StyledGridRow isFullMobile noMargin>
        <div ref={sliderEl} className="swiper-container">
          <StyledPartnersCarouselSwiperWrapper className="swiper-wrapper">
            {partners.map((partner, index) => (
              <StyledPartnersCarouselSlide key={index} className="swiper-slide">
                <StyledPartnersCarouselItem>
                  <a href={partner.url === "/" ? null : partner.url} target="_blank" rel="noreferrer">
                    <StyledPartnersCarouselItemLogo src={partner.logo} />
                  </a>
                </StyledPartnersCarouselItem>
              </StyledPartnersCarouselSlide>
            ))}
          </StyledPartnersCarouselSwiperWrapper>
        </div>
        <StyledPartnersCarouselControlPrev ref={prevArrowEl}>
          <Icon icon={icons.chevronLeft} color={Colors.white} />
        </StyledPartnersCarouselControlPrev>
        <StyledPartnersCarouselControlNext ref={nextArrowEl}>
          <Icon icon={icons.chevronRight} color={Colors.white} />
        </StyledPartnersCarouselControlNext>
      </StyledGridRow>
    </StyledPartnersCarousel>
  );
};
