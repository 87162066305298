import React, { useContext } from "react";
import {
  StyledIntro,
  StyledIntroImageWrapper,
  StyledIntroImage,
  StyledIntroGalleryWrapper,
  StyledIntroBlock,
  StyledIntroTitle,
  StyledIntroSubtitle,
  StyledIntroBigText,
  StyledIntroSmallText,
  StyledIntroCTA,
  StyledQrCode,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import Carousel from "../Carousel/Carousel";
import { Colors } from "../../commons/Theme";
import { DataContext } from "../../contexts/DataContext";

const Intro = ({
  title,
  image,
  fullheight = true,
  qrCode,
  qrCodeLink="/2020/svg/icons/filtrorifiorisci.svg",
  gallery,
  subtitle,
  bigText,
  smallText,
  cta,
  hideTextOnMobile,
  showSubtitleMobile,
  isCentered,
  noPadding,
  themeColor = {},
  marginTop,
  id,
}) => {
  const { i18nLink } = useContext(DataContext);

  return (
    <StyledGridRow id={id} noMargin isFull={!!image || !!gallery || noPadding}>
      <StyledIntro
        isCentered={isCentered}
        gray={!!image || !!gallery}
        black={themeColor && themeColor.black}
        hasCTA={!!cta}
        hasImage={!!image}
        hasGallery={!!gallery}
        marginTop={marginTop}
      >
        {image && (
          <StyledIntroImageWrapper fullheight={fullheight}>
            <StyledIntroImage bg={image} fullheight={fullheight}/>
          </StyledIntroImageWrapper>
        )}
        {gallery && (
          <StyledIntroGalleryWrapper>
            <Carousel items={gallery} withThumbs />
          </StyledIntroGalleryWrapper>
        )}
        <StyledIntroBlock>
          {(!image || !gallery) && (
            <>
              {title && (
                <StyledIntroTitle
                  hideTextOnMobile={hideTextOnMobile}
                  showSubtitleMobile={showSubtitleMobile}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              <StyledIntroSubtitle
                showSubtitleMobile={showSubtitleMobile}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            </>
          )}
          {!gallery && cta && (
            <StyledIntroCTA>
              {themeColor.black ? (
                <Button as="div" theme="transparent" noPadding to={cta.url ? i18nLink(`${cta.url}`) : null}>
                  {cta.label}{" "}
                  <Icon icon={icons.arrowRight} color={Colors.white} />
                </Button>
              ) : (
                <Button
                  as="div"
                  theme={!!image || !!gallery ? "grey" : "white"}
                  noPadding
                  to={cta.url ? i18nLink(`${cta.url}`) : null}
                >
                  {cta.label}{" "}
                  <Icon
                    icon={icons.arrowRight}
                    color={gallery ? Colors.yellow : Colors.black}
                  />
                </Button>
              )}
            </StyledIntroCTA>
          )}
        </StyledIntroBlock>
        <StyledIntroBlock hasPadding={!!image || !!gallery}>
          {!!image
            || (!!gallery && (
              <StyledIntroTitle
                hideTextOnMobile={hideTextOnMobile}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            ))}
          <StyledIntroBigText dangerouslySetInnerHTML={{ __html: bigText }} />
          {smallText && (
            <StyledIntroSmallText
              dangerouslySetInnerHTML={{ __html: smallText }}
            />
          )}
          {!!gallery && cta && (
            <StyledIntroCTA>
              <Button
                as="div"
                theme={!!image || !!gallery ? "grey" : "white"}
                noPadding
                to={cta.url ? i18nLink(`${cta.url}`) : null}
              >
                {cta.label}{" "}
                <Icon
                  icon={icons.arrowRight}
                  color={gallery ? Colors.yellow : Colors.black}
                />
              </Button>
            </StyledIntroCTA>
          )}
          {!!qrCode && (
            <StyledQrCode src={qrCodeLink} loading="lazy"/>
          )}
        </StyledIntroBlock>
      </StyledIntro>
    </StyledGridRow>
  );
};

export default Intro;
